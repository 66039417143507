<template>
  <section class="section flex banner-block">
    <div class="f banner_wrap">
      <div class="lside">
        <img
            :src="getImageFromProxy(
                `${siteHost}/img/graph.png`
            )"
            alt="graph"
            height="44"
            loading="eager"
            width="149" />
        <p class="f16 green">
          {{ $t('homepage.subtitle') }}
        </p>
        <h1 class="f32">{{ $t('homepage.title') }}</h1>
      </div>
      <div class="rside">
        <div class="skin">
          <div class="skin_info">
            <p class="light f12">AK-47</p>
            <p class="h5 f14">Wasterland Rebel</p>
            <p class="f fs light">
              <span class="f green aup mr10">+34%</span>
              <span>{{ $t('in_the_last_24hr') }}</span>
            </p>
          </div>
          <!--          <img-->
          <!--              v-show="!isImageLoaded"-->
          <!--              :src="placeholderImage"-->
          <!--              alt="ak47 banner"-->
          <!--              class="opacity-50"-->
          <!--              height="400"-->
          <!--              loading="eager"-->
          <!--              width="400"-->
          <!--          />-->
          <!--              v-show="isImageLoaded"-->
          <img
              :src="getImageFromProxy(
                `${siteHost}/img/banners/b01.webp`,
                currW > 768 ? '1000:1000' : '400:400'
              )"
              alt="ak47 banner"
              height="400"
              loading="eager"
              width="400"
          />
          <!--              @load="onImageLoad"-->
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {getImageFromProxy} from "~/utils/get-image-url";

const config = useRuntimeConfig()
const siteHost = config.public.siteHost

const currW = useResize()

// const isImageLoaded = ref(false);
// const placeholderImage = ref(getImageFromProxy(
//     `${siteHost}/img/banners/b01.webp`,
//     '50:50'
// ));
//
// // Функция для замены изображения после загрузки
// const onImageLoad = () => {
//   isImageLoaded.value = true;
// };
</script>

<style lang="scss" scoped>
@import '/assets/scss/mixins.scss';

.opacity-50 {
  filter: blur(10px);
}

.section {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.lside {
  max-width: 45rem;

  .green {
    margin: 1.6rem 0;
  }

  .sub {
    margin-top: 2.4rem;
    line-height: 2.7rem;
  }
}

.skin {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 40rem;

  &_info {
    position: absolute;
    top: 14rem;
    left: -10rem;
  }

  .h5 {
    margin: 0.8rem 0 1rem;
  }

  img {
    width: 100%;
    height: 40rem;
    object-fit: cover;
  }
}

@include _979 {
  .lside {
    position: relative;
    z-index: 1;
  }
  h1 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
  .skin {
    position: absolute;
    left: 0;
    right: 0;
    top: 6rem;
    opacity: 0.1;
    margin-left: auto;

    &_info {
      display: none;
    }
  }
}
</style>
