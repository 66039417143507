<template>
  <main class="main page-main">
    <IndexPage />
  </main>
</template>

<script lang="ts" setup>
import {useRuntimeConfig} from 'nuxt/app'
import {defineWebPage, useSchemaOrg} from "@unhead/schema-org";
import IndexPage from "~/components/Index/Index.vue";
import {useAlternateLinks} from "~/composables/alternate-links";


const {locale, t} = useI18n()
const route = useRoute()
const config = useRuntimeConfig()
const siteHost = config.public.siteHost
const {alternateLinks, canonical} = useAlternateLinks('')

useHead({
  meta: [
    {
      name: "keywords",
      content: "steam, counter strike global offensive, csgo, skins, items",
    },
  ],
  link: () => alternateLinks.value
})

useSeoMeta({
  title: () => t('heads.homepage.title'),
  ogTitle: () => t('heads.homepage.title'),
  description: () => t('heads.homepage.description'),
  ogDescription: () => t('heads.homepage.description'),
  ogImage: () => 'https://csmarketcap.com/img/logo-bg-black.png',
  twitterCard: 'summary_large_image',
  twitterTitle: () => t('heads.homepage.title'),
  twitterDescription: () => t('heads.homepage.description'),
  twitterImage: 'https://csmarketcap.com/img/logo-bg-black.png',
  ogSiteName: 'CSMarketCap',
  ogUrl: () => canonical.value,
  ogType: 'website',
  ogLocale: () => locale.value
})

useSchemaOrg([
  defineWebPage({
    name: t('heads.homepage.title'),
    description: t('heads.homepage.description'),
    url: siteHost,
    image: 'https://csmarketcap.com/img/logo-bg-black.png',
    publisher: {
      "@type": "Organization",
      name: "CSMarketCap",
      logo: {
        "@type": "ImageObject",
        "url": '/favicon.png'
      }
    }
  }),
])
</script>
